"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutoTrigger = void 0;
var react_1 = require("react");
var sendAnalyticEvents_1 = require("./sendAnalyticEvents");
var AutoTrigger = function (_a) {
    var chatMethod = _a.chatMethod, currentProperty = _a.currentProperty, url = _a.url;
    (0, react_1.useEffect)(function () {
        if (currentProperty) {
            (0, sendAnalyticEvents_1.sendAnalyticEvents)(chatMethod, currentProperty);
            window.open(url, '_self');
        }
    }, [currentProperty]);
    return undefined;
};
exports.AutoTrigger = AutoTrigger;
