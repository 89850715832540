"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fullName = exports.shortName = void 0;
/** Returns a short name for the property, either the neighborhood or the city. */
var shortName = function (_a) {
    var city = _a.city, neighborhood = _a.neighborhood;
    return neighborhood !== null && neighborhood !== void 0 ? neighborhood : city;
};
exports.shortName = shortName;
/** Returns a full name for the property including both the city and neighborhood (when available?). */
var fullName = function (_a) {
    var city = _a.city, neighborhood = _a.neighborhood;
    return "".concat(city).concat(neighborhood ? " ".concat(neighborhood) : '');
};
exports.fullName = fullName;
