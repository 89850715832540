"use strict";
var _a, _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyChooser = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Language_1 = require("./Language");
var Property_1 = require("./Property");
var Messages = {
    ToStart: (_a = {},
        _a[Language_1.Language.English] = 'To start, please tell me where you are.',
        _a[Language_1.Language.French] = 'Pour commencer, veuillez me dire où vous êtes.',
        _a),
    SelectHotel: (_b = {},
        _b[Language_1.Language.English] = function (method) {
            return "Please select the hotel you are staying at and you can ask RENAI anything you need via ".concat(method, ".");
        },
        _b[Language_1.Language.French] = function (method) {
            return "Veuillez s\u00E9lectionner l'h\u00F4tel dans lequel vous s\u00E9journez et vous pourrez demander \u00E0 RENAI tout ce dont vous avez besoin via ".concat(method, ".");
        },
        _b),
    ImInCity: (_c = {},
        _c[Language_1.Language.English] = function (location) { return "I'm in ".concat(location, "."); },
        _c[Language_1.Language.French] = function (location) { return "Je suis \u00E0 ".concat(location, "."); },
        _c),
};
var PropertyChooser = function (_a) {
    var lang = _a.lang, properties = _a.properties, currentProperty = _a.currentProperty, setCurrentProperty = _a.setCurrentProperty, chatMethod = _a.chatMethod;
    if (properties.length === 0) {
        throw new Error('No properties provided.');
    }
    var allInSameCity = properties.every(function (property) { var _a; return property.city === ((_a = properties[0]) === null || _a === void 0 ? void 0 : _a.city); });
    var renderName = allInSameCity ? Property_1.shortName : Property_1.fullName;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("p", { children: chatMethod ? Messages.SelectHotel[lang](chatMethod) : Messages.ToStart[lang] }), (0, jsx_runtime_1.jsx)("div", { className: 'buttons are-medium', children: properties.map(function (property) {
                    var isActive = currentProperty === property;
                    var id = (0, Property_1.fullName)(property);
                    return ((0, jsx_runtime_1.jsx)("button", { className: "button is-rounded is-fullwidth ".concat(isActive ? 'is-white' : 'is-info is-outlined'), onClick: function () {
                            if (!isActive) {
                                setCurrentProperty(property);
                            }
                        }, children: Messages.ImInCity[lang](renderName(property)) }, id));
                }) })] }));
};
exports.PropertyChooser = PropertyChooser;
