"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
require("./index.scss");
var client_1 = require("react-dom/client");
var ts_1 = require("../common/ts");
var domNode = document.getElementById('app');
if (!domNode) {
    throw new Error("Unable to find element with ID 'app'.");
}
var root = (0, client_1.createRoot)(domNode);
root.render((0, jsx_runtime_1.jsx)(ts_1.AskRENAI, { lang: ts_1.Language.English, properties: [
        {
            city: 'Charleston',
        },
        {
            city: 'Nashville',
        },
        {
            city: 'Plano',
        },
    ] }));
